import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CContainer,
  CSwitch,
  CSelect,
  CCardFooter,
} from "@coreui/react";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import useInteractiveStore from "src/stores/Interactive";
import { interactiveType } from "src/stores/types";
import useChannelsStore from "src/stores/Channels";
import firebase from "firebase";

let userProfileSubscriber = () => {};

const Interactivity = ({ match }) => {
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();

  const {
    interactives,
    profileInteractions,
    interactiveStatus,
    interactiveError,
    getChannelInteractives,
    updateInteractivity,
    deleteInteractivity,
    getUserProfileInteractions,
    resetProfileInteractions,
  } = useInteractiveStore();

  const { getChannels, channelsData } = useChannelsStore();
  const history = useHistory();
  const [darkMode, setDarkMode] = useState(true);
  //const usersData = useSelector(state => state.usersData)
  const [modalAlert, setModalAlert] = useState(null);
  // const [interactives, setInteractives] = useState([]);
  const [currentActive, setCurrentActive] = useState(null);
  const [currentChannel, setCurrentChannel] = useState(
    channelsData[match.params.id]
  );
  const [currentActiveInteractions, setCurrentActiveInteractions] =
    useState(null);

  useEffect(() => {
    const channelInteractives = interactives?.[match?.params?.id] || [];
    if (channelInteractives.some((x) => x.status === "active")) {
      setCurrentActive(channelInteractives.find((x) => x.status === "active"));
    } else {
      setCurrentActive(null);
      userProfileSubscriber();
    }
  }, [interactives]);

  useEffect(() => {
    getInteractives();
    getChannels();
  }, []);

  const getInteractives = () => {
    if (match.params.id) {
      getChannelInteractives({ channelId: match.params.id });
    }
  };

  const updateInteractive = (id, updates) => {
    updateInteractivity({
      channelId: match.params.id,
      interactiveId: id,
      data: updates,
    });
  };

  useEffect(() => {
    if (interactiveStatus === interactiveType.DELETEINTERACTIVITY_FAIL) {
      setModalAlert({
        title: "Unable to Delete",
        message: interactiveError,
        color: "danger",
        buttons: [{ title: "OK", onClick: () => setModalAlert(null) }],
      });
    }
  }, [interactiveStatus]);

  useEffect(() => {
    if (currentActive) {
      getUserProfileInteractions({
        setUnsubscribe: (func) => (userProfileSubscriber = func),
        channelId: currentActive?.channelId,
        interactiveId: currentActive?.interactiveId,
      });
    } else {
      setCurrentActive(null);
      userProfileSubscriber();
    }
  }, [currentActive]);

  useEffect(() => {
    if (profileInteractions?.[currentActive?.interactiveId]) {
      const channelInteractions =
        profileInteractions?.[currentActive?.interactiveId];
      let opts = {};
      let total = 0;
      if (currentActive?.type === "quiz") {
        // For QUIZ
        const questionInteractions = {};
        currentActive?.questions?.forEach((qItem) => {
          let qOpts = {};
          let qTotal = 0;
          qItem?.choices?.forEach((item) => {
            const newCounter =
              0 +
              channelInteractions?.filter(
                (obj) =>
                  obj[`question-${qItem?.questionId}`]?.selected === item?.optId
              ).length;
            qOpts[`${item.optId}`] = newCounter;
            qTotal += newCounter;
          });
          qOpts["total"] = qTotal;
          questionInteractions[`${qItem.questionId}`] = qOpts;
        });
        setCurrentActiveInteractions(questionInteractions);
      } else {
        // For POLLS
        currentActive?.options?.forEach((item) => {
          const newCounter =
            0 +
            channelInteractions?.filter((obj) => obj.selected === item.optId)
              .length;
          opts[`${item.optId}`] = newCounter;
          total += newCounter;
        });
        opts["total"] = total;
        setCurrentActiveInteractions(opts);
      }
    }
  }, [profileInteractions, currentActive]);

  return (
    <div className={`c-app ${darkMode ? "c-dark-theme" : ""}`}>
      <CContainer fluid>
        <CButton
          className="btn-danger mt-3 pt-3 d-flex justify-content-center align-items-center"
          onClick={() => history.push(`/channels`)}
        >
          <h6>{"< Back to Channels"}</h6>
        </CButton>
        <CRow className="flex-fill">
          <CCol md="8" className="inter-col d-flex flex-column">
            <h1 className="font-weight-bold">
              {currentChannel?.title.en
                ? currentChannel?.title.en
                : currentChannel?.title}
            </h1>
            <h5 className="inter-title">INTERACTIVITY MANAGER</h5>
            <div className="">
              {currentActive ? (
                <CCard borderColor="primary">
                  <CCardHeader>
                    <h5 className="font-weight-bold inter-type">
                      {currentActive?.type}
                    </h5>
                  </CCardHeader>
                  <CCardBody
                    className="inter-col-content"
                    style={{ height: "500px" }}
                  >
                    <h1>{currentActive?.title}</h1>
                    <h4 className="mt-4">
                      {currentActive?.type === "quiz" ? "Questions" : "Options"}
                      :
                    </h4>
                    {currentActive?.type !== "quiz"
                      ? currentActive?.options?.map((item, index) => {
                          return (
                            <CCard
                              key={`c-${index}`}
                              color="secondary"
                              className="mb-2"
                            >
                              <CCardBody className="p-3 d-flex align-items-center justify-content-between ">
                                <div className="d-flex align-items-center ">
                                  {item.imageUrl ? (
                                    <img
                                      src={item.imageUrl}
                                      className="inter-opt-img"
                                    />
                                  ) : null}
                                  {item.iconUrl ? (
                                    <img
                                      src={item.iconUrl}
                                      className="inter-opt-img"
                                    />
                                  ) : null}
                                  <h5 className="mb-0">{item.text}</h5>
                                </div>
                                <div className="d-flex align-items-center ">
                                  <CBadge className="mr-2">
                                    <h4 className="mb-0">
                                      {currentActiveInteractions?.[
                                        `${item.optId}`
                                      ] || 0}
                                    </h4>
                                  </CBadge>
                                  <CBadge color="info">
                                    <h4 className="mb-0">
                                      {(
                                        ((currentActiveInteractions?.[
                                          `${item.optId}`
                                        ] || 0) /
                                          currentActiveInteractions?.[
                                            "total"
                                          ]) *
                                          100 || 0
                                      ).toFixed() || 0}
                                      %
                                    </h4>
                                  </CBadge>
                                </div>
                              </CCardBody>
                            </CCard>
                          );
                        })
                      : null}
                    {currentActive?.type === "quiz"
                      ? currentActive?.questions?.map((qItem, qIndex) => {
                          return (
                            <CCard
                              key={`k-${qIndex}`}
                              borderColor={
                                currentActive?.currentQuestion === qIndex
                                  ? "primary"
                                  : "light"
                              }
                            >
                              <CCardBody
                                className="p-2"
                                style={
                                  currentActive?.currentQuestion === qIndex
                                    ? { backgroundColor: "rgba(70,56,194,0.3)" }
                                    : {}
                                }
                              >
                                <h4>
                                  <CButton
                                    color="warning"
                                    className="mr-2"
                                    onClick={() => {
                                      updateInteractive(
                                        currentActive?.interactiveId,
                                        {
                                          currentQuestion: qIndex,
                                        }
                                      );
                                    }}
                                  >
                                    {qIndex + 1}
                                  </CButton>
                                  {qItem.question}
                                </h4>
                                {qItem?.choices?.map((cItem, cIndex) => {
                                  return (
                                    <CCard
                                      key={`c-${cIndex}`}
                                      color="secondary"
                                      className="mb-2"
                                    >
                                      <CCardBody
                                        className="p-2 d-flex align-items-center justify-content-between "
                                        style={
                                          qItem.choicesAnswer === cIndex
                                            ? {
                                                backgroundColor:
                                                  "rgba(0,255,0,0.2)",
                                              }
                                            : {}
                                        }
                                      >
                                        <div className="d-flex align-items-center ">
                                          {cItem.imageUrl ? (
                                            <h4>{cItem.imageUrl}</h4>
                                          ) : // <img src={item.imageUrl} className="inter-opt-img" />
                                          null}
                                          {cItem.iconUrl ? (
                                            <img
                                              src={cItem.iconUrl}
                                              className="inter-opt-img"
                                            />
                                          ) : null}
                                          <h5 className="mb-0">{cItem.text}</h5>
                                        </div>
                                        <div className="d-flex align-items-center ">
                                          <CBadge className="mr-2">
                                            <h4 className="mb-0">
                                              {currentActiveInteractions?.[
                                                `${qItem.questionId}`
                                              ]?.[`${cItem.optId}`] || 0}
                                            </h4>
                                          </CBadge>
                                          <CBadge color="info">
                                            <h4 className="mb-0">
                                              {(
                                                ((currentActiveInteractions?.[
                                                  `${qItem.questionId}`
                                                ]?.[`${cItem.optId}`] || 0) /
                                                  currentActiveInteractions?.[
                                                    `${qItem.questionId}`
                                                  ]?.[`total`]) *
                                                  100 || 0
                                              ).toFixed() || 0}
                                              %
                                            </h4>
                                          </CBadge>
                                        </div>
                                      </CCardBody>
                                    </CCard>
                                  );
                                })}
                              </CCardBody>
                            </CCard>
                          );
                        })
                      : null}
                  </CCardBody>
                  <CCardFooter className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      {currentActive?.type === "quiz" ? (
                        <>
                          <CButton
                            color="primary"
                            className="mr-2"
                            onClick={() => {
                              updateInteractive(currentActive?.interactiveId, {
                                currentQuestion:
                                  currentActive?.currentQuestion - 1,
                              });
                            }}
                            disabled={!currentActive?.currentQuestion}
                          >
                            PREV
                          </CButton>
                          <span>Q# </span>
                          <CSelect
                            color="danger"
                            custom
                            name="currentQues"
                            id="currentQues"
                            className="ml-2 mr-2"
                            style={{ width: 60 }}
                            onChange={(e) => {
                              updateInteractive(currentActive?.interactiveId, {
                                currentQuestion: Number(e.target.value),
                              });
                            }}
                            value={currentActive?.currentQuestion}
                          >
                            {[
                              ...new Array(currentActive?.questions.length),
                            ].map((item, index) => {
                              return (
                                <option key={`choice-${index}`} value={index}>
                                  {index + 1}
                                </option>
                              );
                            })}
                          </CSelect>
                          <CButton
                            color="primary"
                            className="mr-2"
                            onClick={() => {
                              updateInteractive(currentActive?.interactiveId, {
                                currentQuestion:
                                  currentActive?.currentQuestion + 1,
                              });
                            }}
                            disabled={
                              currentActive?.currentQuestion >=
                              currentActive?.questions?.length - 1
                            }
                          >
                            NEXT
                          </CButton>
                        </>
                      ) : null}
                    </div>
                    <div className="d-flex align-items-center">
                      {currentActive?.type === "quiz" ? (
                        <CButton
                          className="d-flex align-items-center p-2 mr-2"
                          color={currentActive?.showQuiz ? "success" : "dark"}
                          onClick={() => {
                            const updateData = {
                              showQuiz: !currentActive?.showQuiz,
                            };
                            if (currentActive?.showQuiz === false) {
                              updateData.activatedAt = timestamp;
                            } else {
                              updateData.deactivatedAt = timestamp;
                            }
                            updateInteractive(
                              currentActive?.interactiveId,
                              updateData
                            );
                          }}
                        >
                          <h6 className="mb-0 mr-2">SHOW QUIZ</h6>
                          <CSwitch
                            className=""
                            color="success"
                            checked={currentActive?.showQuiz}
                            onChange={() => {}}
                          />
                        </CButton>
                      ) : null}
                      <CButton
                        size="md"
                        color="danger"
                        className={"mr-2"}
                        onClick={() => {
                          resetProfileInteractions({
                            channelId: currentActive?.channelId,
                            interactiveId: currentActive?.interactiveId,
                          });
                        }}
                      >
                        <h5 className="mb-0">Reset</h5>
                      </CButton>
                      <CButton
                        size="md"
                        color="danger"
                        onClick={() =>
                          updateInteractive(currentActive.interactiveId, {
                            status: "inactive",
                          })
                        }
                      >
                        <h5 className="mb-0">STOP INTERACTIVITY</h5>
                      </CButton>
                    </div>
                  </CCardFooter>
                </CCard>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  NO ACTIVE OVERLAY
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center inter-controller"></div>
          </CCol>
          <CCol md="4" className="inter-col">
            <div className="d-flex justify-content-between mb-4">
              <div>
                <label className="mr-2">Sort by: </label>
                <CSelect
                  color="danger"
                  className="inter-filter"
                  custom
                  name="filter"
                  id="filter"
                  // value={this.state.difficultyCode}
                  onChange={(e) => {}}
                >
                  {[{ name: "By Date", value: "date" }].map((item, index) => {
                    return (
                      <option key={`date-${index}`} value={`${item.value}`}>
                        {item.name}
                      </option>
                    );
                  })}
                </CSelect>
              </div>
              <CButton
                size="lg"
                color="primary"
                onClick={() =>
                  history.push(`/channels/interactivity/${match.params.id}/new`)
                }
              >
                + Create Interactivity
              </CButton>
            </div>
            <div className="inter-col-content">
              {interactives?.[match.params.id]?.map((item, index) => {
                return (
                  <CCard
                    key={`e-${index}`}
                    color={item.status === "active" ? "primary" : ""}
                    borderColor="light"
                  >
                    <CCardHeader className="d-flex justify-content-between p-2">
                      <div>
                        <h5 className="mb-0 inter-type">{item.type}</h5>
                      </div>
                      <CSwitch
                        className=""
                        color="success"
                        checked={item.status === "active"}
                        onChange={() => {
                          if (
                            currentActive &&
                            currentActive?.interactiveId !==
                              item.interactiveId &&
                            item.status !== "active"
                          ) {
                            updateInteractive(currentActive?.interactiveId, {
                              status: "inactive",
                              deactivatedAt: timestamp,
                            });
                          }
                          item.status === "active"
                            ? updateInteractive(item.interactiveId, {
                                status:
                                  item.status === "active"
                                    ? "inactive"
                                    : "active",
                                deactivatedAt: timestamp,
                              })
                            : updateInteractive(item.interactiveId, {
                                status:
                                  item.status === "active"
                                    ? "inactive"
                                    : "active",
                                activatedAt: timestamp,
                              });
                        }}
                      />
                    </CCardHeader>
                    <CCardBody className="p-2 d-flex justify-content-between align-items-end">
                      <div>
                        <h3 className="mb-1">{item.title}</h3>
                        <CBadge color={"success"} className="mr-2">
                          {item.type === "quiz"
                            ? `${item.questions?.length} questions`
                            : `${item.options?.length} options`}
                        </CBadge>
                        <br />
                        <CBadge color={"light"} className="mr-2">
                          Created:{" "}
                          {item?.createdAt
                            ? moment(item.createdAt.toDate()).format("lll")
                            : ""}
                        </CBadge>
                        <CBadge color={"light"} className="mr-2">
                          Updated:{" "}
                          {item?.updatedAt
                            ? moment(item.updatedAt.toDate()).format("lll")
                            : ""}
                        </CBadge>
                      </div>
                      <div>
                        <CButton
                          size={"sm"}
                          className="ml-2"
                          color="info"
                          onClick={(e) => {
                            history.push(
                              `/channels/interactivity/${match.params.id}/edit/${item.interactiveId}`
                            );
                          }}
                        >
                          <CIcon name="cil-pencil" />
                        </CButton>
                        <CButton
                          size={"sm"}
                          className="ml-2"
                          color="danger"
                          onClick={(e) => {
                            // removeQuestion(qItem)

                            e.stopPropagation();
                            setModalAlert({
                              title: "Delete Interactivity",
                              message: `Are you sure you want to delete this Interactivity?`,
                              color: "danger",
                              buttons: [
                                {
                                  title: "Delete",
                                  color: "danger",
                                  onClick: () => {
                                    setModalAlert(null);
                                    deleteInteractivity({
                                      channelId: item.channelId,
                                      interactiveId: item.interactiveId,
                                    });
                                  },
                                },
                                {
                                  title: "Cancel",
                                  color: "secondary",
                                  onClick: () => setModalAlert(null),
                                },
                              ],
                            });
                          }}
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </div>
                    </CCardBody>
                  </CCard>
                );
              })}
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <CModal
        show={modalAlert ? true : false}
        onClose={() => {
          setModalAlert(null);
          history.push("/");
        }}
        color={modalAlert?.color || "secondary"}
      >
        <CModalHeader closeButton>
          <CModalTitle>{modalAlert?.title || ""}</CModalTitle>
        </CModalHeader>
        <CModalBody>{modalAlert?.message || ""}</CModalBody>
        <CModalFooter>
          {modalAlert?.buttons?.map((item, index) => {
            return (
              <CButton
                key={`m-${index}`}
                color={item.color || "secondary"}
                onClick={item.onClick}
              >
                {item.title}
              </CButton>
            );
          })}
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default Interactivity;
